<template>
<div style="display: unset;"> 
    <nav class="navbar navbar-light navbar-glass navbar-top navbar-expand" style="margin-top:-20px">
        <button class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse" aria-controls="navbarVerticalCollapse" aria-expanded="false" aria-label="Toggle Navigation"><span class="navbar-toggle-icon"><span class="toggle-line"></span></span></button>
        <a class="navbar-brand me-1 me-sm-3" href="/avidi">
            <div class="d-flex align-items-center"><img class="me-2" src="/assets/img/avidi_img/avidi_logo.png" alt="" width="40" /><span class="font-sans-serif">AviDi</span>
            </div>
        </a>
        <ul class="ms-auto flex-row ticker-container">
            <div class="ticker">
                <li v-for="(recency, name) in recencyList" :key="name" :class="['ticker__item',recency.status]">
                    <strong>{{ name }}</strong> <span>{{ recency.recency }}. Will Expire on {{ recency.expDate }}</span>
                </li>
            </div>
        </ul>
        <ul class="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
            <li class="nav-item">
                <div class="theme-control-toggle fa-icon-wait px-2">
                    <input class="form-check-input ms-0 theme-control-toggle-input" id="themeControlToggle" @click="themeChange" type="checkbox" data-theme-control="theme" value="dark" />
                    <label class="mb-0 theme-control-toggle-label theme-control-toggle-light" for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left" v-tooltip.left="'Switch to light theme'"><span class="fas fa-sun fs-0"></span></label>
                    <label class="mb-0 theme-control-toggle-label theme-control-toggle-dark" for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left" v-tooltip.left="'Switch to dark theme'"><span class="fas fa-moon fs-0"></span></label>
                </div>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link pe-0" id="navbarDropdownUser" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div class="avatar avatar-xl">
                        <img class="rounded-circle" :src="previewAvatar" alt="" />
                    </div>
                </a>
                <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="navbarDropdownUser">
                    <div class="bg-white dark__bg-1000 rounded-2 py-2">
                        <a class="dropdown-item" :href="baseUrl+'/my-profile'">Profile &amp; Account</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#" @click.prevent="logout">Logout</a>
                    </div>
                </div>
            </li>
        </ul>
    </nav>
    <div v-if="showPaymentExceedBanner">
        <div v-if="Number.isInteger(color)" class="alert alert-danger border-2 d-flex align-items-center " role="alert">
            <div class="bg-danger me-3 icon-item"><span class="fas fa-exclamation-circle text-white fs-3"></span></div>
            <p class="mb-0 flex-1">{{alertMessage}}</p>
            <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div v-else-if="color == true" class="alert alert-warning border-2 d-flex align-items-center " role="alert">
            <div class="bg-warning me-3 icon-item"><span class="fas fa-exclamation-circle text-white fs-3"></span></div>
            <p class="mb-0 flex-1">{{alertMessage}}</p>
            <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div v-else class="alert alert-info border-2 d-flex align-items-center " role="alert">
            <div class="bg-info me-3 icon-item"><span class="fas fa-exclamation-circle text-white fs-3"></span></div>
            <p class="mb-0 flex-1">{{alertMessage}}</p>
            <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    </div>

    <!-- Password Change Model  -->
    <div class="modal fade" id="password-modal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 500px">
                <div class="modal-content position-relative">
                    <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                        <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close" @click="resetForm()"></button>
                    </div>
                    <b-form @submit.stop.prevent="UpdatePassword" id="UpdatePassword" autocomplete="off">
                        <div class="modal-body p-0">
                            <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                                <h4 class="mb-1" id="modalExampleDemoLabel">
                                    Change Password
                                </h4>
                            </div>
                            <div class="p-4 pb-0">

                                <div class="mb-3">
                                    <b-form-group id="password-input-group-1" class="dark__text-white" label="Current Password" label-for="oldPassword">
                                        <b-input-group>
                                            <b-form-input class="form-control-sm" :type="opwType" id="oldPassword" placeholder="Current Password" name="newPassword" v-model="$v.resetPasswordFrom.oldPassword.$model" :state="validateState('oldPassword')" aria-describedby="input-oldPassword-feedback" autocomplete="off"></b-form-input>
                                            <b-input-group-prepend is-text @click="showHidePassword('current')" style="display:flex;">
                                                <b-icon v-show="showCPW" icon="eye-slash"></b-icon>
                                                <b-icon v-show="!showCPW" icon="eye"></b-icon>
                                            </b-input-group-prepend>
                                            <b-form-invalid-feedback id="input-oldPassword-feedback">This is a required field.</b-form-invalid-feedback>
                                        </b-input-group>
                                    </b-form-group>
                                </div>
                                <div class="mb-3">
                                    <b-form-group id="password-input-group-2" class="dark__text-white" label="New Password" label-for="newPassword">
                                        <b-input-group>
                                            <b-form-input class="form-control-sm" :type="npwType" autocomplete="off" id="newPassword" placeholder="New Password" name="newPassword" v-model="$v.resetPasswordFrom.newPassword.$model" :state="validateState('newPassword')" aria-describedby="input-newPassword-feedback"></b-form-input>
                                            <b-input-group-prepend is-text @click="showHidePassword('new')" style="display:flex;">
                                                <b-icon v-show="showNPW" icon="eye-slash"></b-icon>
                                                <b-icon v-show="!showNPW" icon="eye"></b-icon>
                                            </b-input-group-prepend>
                                            <b-form-invalid-feedback id="input-newPassword-feedback">This is a required field.</b-form-invalid-feedback>
                                        </b-input-group>
                                    </b-form-group>
                                </div>
                                <div class="mb-3">
                                    <b-form-group id="password-input-group-13" class="dark__text-white" label="Confirm Password" label-for="confirmPassword">
                                        <b-input-group>
                                            <b-form-input class="form-control-sm" autocomplete="off" :type="cpwType" @focus="error = ''" id="confirmPassword" placeholder="Confirm Password" name="confirmPassword" v-model="$v.resetPasswordFrom.confirmPassword.$model" :state="validateState('confirmPassword')" aria-describedby="input-confirmPassword-feedback"></b-form-input>
                                            <b-input-group-prepend is-text @click="showHidePassword('confirm')" style="display:flex;">
                                                <b-icon v-show="showCnPW" icon="eye-slash"></b-icon>
                                                <b-icon v-show="!showCnPW" icon="eye"></b-icon>
                                            </b-input-group-prepend>
                                            <b-form-invalid-feedback id="input-confirmPassword-feedback">This is a required field.</b-form-invalid-feedback>
                                        </b-input-group>
                                    </b-form-group>
                                </div>
                                <div class="mb-3">
                                    <span style="color:red">{{passwordValidateError}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-sm btn-secondary" type="button" data-bs-dismiss="modal" @click="passwordValidateError=''">
                                Close
                            </button>
                            <button class="btn btn-sm btn-primary" type="submit">
                                Update Password
                            </button>

                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    

</div>

<!--  IMPORT PAGE CONTENTS -->
</template>

<script>
import API from "../apiConfig";
import {
    validationMixin
} from "vuelidate";
import {
    required,
    minLength
} from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';

export default {
    props: ["auth_user"],
    name: "rightBar",
    mixins: [validationMixin],
    computed: {
        ...mapGetters(['recencyList']),
    },
    data() {
        return {
            api: API.API_URL,
            baseUrl: API.BASE_URL,
            previewAvatar: "",
            AuthUser: this.auth_user,
            showPaymentExceedBanner: false,
            alertMessage: "",
            color: false,
            passwordValidateError: "",
            showCPW: false,
            opwType: 'password',

            showNPW: false,
            npwType: 'password',

            showCnPW: false,
            cpwType: 'password',

            thisRecency: null,
            // ResetPassword Form
            resetPasswordFrom: {
                oldPassword: null,
                newPassword: null,
                confirmPassword: null
            },
        }
    },
    mounted() {
        const vm = this;
        if (this.AuthUser.avatar == "" || this.AuthUser.avatar == null) {
            this.previewAvatar = "/assets/img/avidi_img/profile_avatar/default_avatar.png";
        } else {
            this.previewAvatar =
                "/assets/img/avidi_img/profile_avatar/" + this.AuthUser.avatar;
        }
        this.checkPasswordIsExpired();
        this.checkPayment();
    },
    created(){
        
    },
    validations:{
        resetPasswordFrom: {
            oldPassword: {
                required
            },
            newPassword: {
                required
            },
            confirmPassword: {
                required
            }
        },
    },
    methods: {
        logout() {
            const vm = this;
            this.$alertify.confirm(
            'Are you sure, you want to sign out ?',
            async () => {
                axios.post(vm.baseUrl+"/api/signout", {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('AppToken'),
                    }
                }).then(response => {
                    localStorage.removeItem('AppToken');
                    // delete axios.defaults.headers.common['Authorization'];
                    vm.$router.go('/login');
                });
            });
        },
        themeChange() {
            const vm = this;
            let theme = localStorage.getItem('theme');

            if (theme == 'dark') {

            } else {

            }
        },
        async checkPayment() {
            const vm = this;
            await axios.get(vm.baseUrl+'/'+vm.api + "/check-system-payment").then((response) => {
                vm.showPaymentExceedBanner = response.data.data;
                vm.alertMessage = response.data.data.message;
                vm.color =  response.data.data.due;
            }).catch((e) => {
                console.log(e);
            });
        },

        showHidePassword(from) {
            const vm = this;
            if (from == 'current') {
                vm.showCPW = !vm.showCPW;
                if (vm.showCPW == true) {
                    vm.opwType = 'text'
                } else {
                    vm.opwType = 'password'
                }
            } else if (from == 'new') {
                vm.showNPW = !vm.showNPW;
                if (vm.showNPW == true) {
                    vm.npwType = 'text'
                } else {
                    vm.npwType = 'password'
                }
            } else if (from == 'confirm') {
                vm.showCnPW = !vm.showCnPW;
                if (vm.showCnPW == true) {
                    vm.cpwType = 'text'
                } else {
                    vm.cpwType = 'password'
                }
            }
        },

        UpdatePassword() {
            this.$v.resetPasswordFrom.$touch();
            if (this.$v.resetPasswordFrom.$anyError) {
                return false;
            } else {
                const vm = this;
                var userId = vm.AuthUser._id;
                vm.passwordValidateError = "";
                if (vm.$route.params.id) {
                    userId = vm.$route.params.id;
                }
                if (vm.resetPasswordFrom.newPassword != vm.resetPasswordFrom.confirmPassword) {
                    vm.passwordValidateError = 'Password does not match'
                } else {
                    axios({
                        method: "POST",
                        url: vm.api + "/updatePass/" + userId,
                        data: vm.resetPasswordFrom,
                    }).then(function (response) {
                        if (response.data.status === true) {
                            $("#UpdatePassword")[0].reset();
                            vm.$toast.open({
                                message: response.data.message,
                                type: 'success',
                            });
                        } else {
                            vm.passwordValidateError = response.data.message;
                            vm.$toast.open({
                                message: response.data.message,
                                type: 'error',
                            });
                        }
                    }).catch((e) => {
                        console.log('Unauthenticated');
                    });
                }

            }
        },

        resetForm(){
            const vm = this;
            vm.passwordValidateError='';
            vm.resetPasswordFrom = {
                oldPassword: null,
                newPassword: null,
                confirmPassword: null
            };
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },

        validateState(name) {
            const {
                $dirty,
                $error
            } = this.$v.resetPasswordFrom[name];
            return $dirty ? !$error : null;
        },

        async checkPasswordIsExpired(){ 
            const vm = this;
            await axios.get(vm.baseUrl+'/'+vm.api + "/check-expired-password").then((response) => {
                if(!response.data.status){
               
                    this.$alertify.alert(
                        response.data.message,
                    async () => { 
                        axios.post("api/signout", {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('AppToken'),
                            }
                        }).then(response => {
                            localStorage.removeItem('AppToken');
                            // delete axios.defaults.headers.common['Authorization'];
                            vm.$router.go('/login');
                        });
                    });
                }
            }).catch((e) => {
                console.log(e);
            });
        }
    }
}
</script>
