<template>
<nav class="navbar navbar-vertical navbar-expand-xl navbar-light navbar-vibrant">
    <!-- <nav class="navbar navbar-light navbar-vertical navbar-expand-xl"> -->
    <div class="d-flex align-items-center">
        <div class="toggle-icon-wrapper">
            <button class="btn navbar-toggler-humburger-icon navbar-vertical-toggle" @click="toggleNavBar"   v-tooltip.right="'Double Click to Toggle'" >
                <span class="navbar-toggle-icon">
                    <span class="toggle-line"></span>
                </span>
            </button>
        </div>
        <a class="navbar-brand" href="/avidi">
            <div class="d-flex align-items-center py-3">
                <img class="me-2" src="assets/img/avidi_img/avidi_logo.png" alt="" width="40" />
                <span class="font-sans-serif">AVIDI</span>
            </div>
        </a>
    </div>
    <div class="collapse navbar-collapse" id="navbarVerticalCollapse">
        <div class="navbar-vertical-content scrollbar">
            <ul class="navbar-nav flex-column mb-3" id="navbarVerticalNav">
                <li class="nav-item">
                    <!-- parent pages-->
                    <router-link class="nav-link dropdown-indicator" to="#dashboard" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="dashboard">
                        <div class="d-flex align-items-center">
                            <span class="nav-link-icon">
                                <span class="fas fa-chart-pie"></span>
                            </span>
                            <span class="nav-link-text ps-1">Dashboard</span>
                        </div>
                    </router-link>

                    <ul class="nav collapse false" id="dashboard">
                        <li class="nav-item">
                            <router-link to="/" active-class="active" class="nav-link" aria-expanded="false" exact>
                                <div class="d-flex align-items-center">
                                    <span class="nav-link-text ps-1">OPS</span>
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li class="nav-item">
                    <!-- label-->
                    <div class="row navbar-vertical-label-wrapper mt-3 mb-2">
                        <div class="col-auto navbar-vertical-label">App</div>
                        <div class="col ps-0">
                            <hr class="mb-0 navbar-vertical-divider" />
                        </div>
                    </div>

                    <!-- speedGo pages-->
                    <!-- <router-link class="nav-link" active-class="active" to="/speed-go" role="button" aria-expanded="false">
                        <div class="d-flex align-items-center">
                            <span class="nav-link-icon">
                                <span class="fas fa-fast-forward"></span>
                            </span><span class="nav-link-text ps-1">Speed Go</span>
                        </div>
                    </router-link> -->

                    <!-- routes pages-->
                    <router-link class="nav-link" active-class="active" to="/flight-routes" role="button" aria-expanded="false">
                        <div class="d-flex align-items-center">
                            <span class="nav-link-icon">
                                <span class="fas fa-route"></span>
                            </span><span class="nav-link-text ps-1">Flights</span>
                        </div>
                    </router-link>

                    <!-- flight pages-->
                    <router-link class="nav-link" active-class="active" to="/flight-planner" role="button" aria-expanded="false">
                        <div class="d-flex align-items-center">
                            <span class="nav-link-icon">
                                <span class="fa fa-plane"></span>
                            </span><span class="nav-link-text ps-1">Flights Planing</span>
                        </div>
                    </router-link>

                    <!-- Roster pages-->
                    <router-link class="nav-link" active-class="active" to="/roster" role="button" aria-expanded="false">
                        <div class="d-flex align-items-center">
                            <span class="nav-link-icon">
                                <span class="fas fa-calendar-alt"></span>
                            </span><span class="nav-link-text ps-1">Roster</span>
                        </div>
                    </router-link>

                    <!-- TimeSheet pages-->
                    <router-link class="nav-link" active-class="active" to="/time-sheets" role="button" aria-expanded="false">
                        <div class="d-flex align-items-center">
                            <span class="nav-link-icon">
                                <span class="fas fa-clock"></span>
                            </span><span class="nav-link-text ps-1">TimeSheets</span>
                        </div>
                    </router-link>

                    <!-- Leave pages-->
                    <!-- v-if="is('DISPATCHERS')" -->
                    <router-link class="nav-link" active-class="active" to="/leave" role="button" aria-expanded="false">
                        <div class="d-flex align-items-center">
                            <span class="nav-link-icon">
                                <span class="fab fa-medrt"></span>
                            </span><span class="nav-link-text ps-1">Leave</span>
                        </div>
                    </router-link>

                    <!-- CRM pages-->
                    <router-link class="nav-link" active-class="active" to="/crm" role="button" aria-expanded="false">
                        <div class="d-flex align-items-center">
                            <span class="nav-link-icon">
                                <span class="fas fa-users"></span>
                            </span><span class="nav-link-text ps-1">CRM</span>
                        </div>
                    </router-link>

                    <!-- reports pages-->
                    <router-link class="nav-link" active-class="active" to="/reports" role="button" aria-expanded="false">
                        <div class="d-flex align-items-center">
                            <span class="nav-link-icon">
                                <span class="fas fa-file-alt"></span>
                            </span><span class="nav-link-text ps-1">Reports</span>
                        </div>
                    </router-link>

                    <!-- system pages-->
                    <router-link class="nav-link" active-class="active" to="/system" role="button" aria-expanded="false">
                        <div class="d-flex align-items-center">
                            <span class="nav-link-icon">
                                <span class="fas fa-atom"></span>
                            </span><span class="nav-link-text ps-1">System</span>
                        </div>
                    </router-link>

                </li>
            </ul>

        </div>
    </div>
</nav>
</template>

<script>
export default {
    mounted() {
        handleNavbarVerticalCollapsed();
        this.assignTable();

    },
    methods: {
        async assignTable() {
            const plugin = document.createElement("script");
            await plugin.setAttribute("src", "/assets/js/theme.js");
            plugin.async = true;
            document.head.appendChild(plugin);
            await plugin.setAttribute("src", "/assets/vendors/list.js/list.min.js");
            plugin.async = true;
            document.head.appendChild(plugin);

        },

        toggleNavBar() {
            // var Selector = {
            //     HTML: "html",
            //     NAVBAR_VERTICAL_TOGGLE: ".navbar-vertical-toggle",
            //     NAVBAR_VERTICAL_COLLAPSE: ".navbar-vertical .navbar-collapse",
            //     ECHART_RESPONSIVE: "[data-echart-responsive]",
            // };
            //  var ClassNames = {
            //     NAVBAR_VERTICAL_COLLAPSED: "navbar-vertical-collapsed",
            //     NAVBAR_VERTICAL_COLLAPSED_HOVER: "navbar-vertical-collapsed-hover",
            // };
            // var html = document.querySelector(Selector.HTML);
            // html.classList.remove(ClassNames.NAVBAR_VERTICAL_COLLAPSED_HOVER);
            handleNavbarVerticalCollapsed();
        }
    }
};

function handleNavbarVerticalCollapsed() {
    // console.log('called');
    var Selector = {
        HTML: "html",
        NAVBAR_VERTICAL_TOGGLE: ".navbar-vertical-toggle",
        NAVBAR_VERTICAL_COLLAPSE: ".navbar-vertical .navbar-collapse",
        ECHART_RESPONSIVE: "[data-echart-responsive]",
    };
    var Events = {
        CLICK: "click",
        MOUSE_OVER: "mouseover",
        MOUSE_LEAVE: "mouseleave",
        NAVBAR_VERTICAL_TOGGLE: "navbar.vertical.toggle",
    };
    var ClassNames = {
        NAVBAR_VERTICAL_COLLAPSED: "navbar-vertical-collapsed",
        NAVBAR_VERTICAL_COLLAPSED_HOVER: "navbar-vertical-collapsed-hover",
    };
    var navbarVerticalToggle = document.querySelector(
        Selector.NAVBAR_VERTICAL_TOGGLE
    );
    var html = document.querySelector(Selector.HTML);
    var navbarVerticalCollapse = document.querySelector(
        Selector.NAVBAR_VERTICAL_COLLAPSE
    );

    if (navbarVerticalToggle) {
        navbarVerticalToggle.addEventListener(Events.CLICK, function (e) {
            navbarVerticalToggle.blur();
            html.classList.toggle(ClassNames.NAVBAR_VERTICAL_COLLAPSED); // Set collapse state on localStorage

            var isNavbarVerticalCollapsed = utils.getItemFromStore(
                "isNavbarVerticalCollapsed"
            );
            utils.setItemToStore(
                "isNavbarVerticalCollapsed",
                !isNavbarVerticalCollapsed
            );
            var event = new CustomEvent(Events.NAVBAR_VERTICAL_TOGGLE);
            e.currentTarget.dispatchEvent(event);
        });
    }

    if (navbarVerticalCollapse) {
        navbarVerticalCollapse.addEventListener(Events.MOUSE_OVER, function () {
            if (utils.hasClass(html, ClassNames.NAVBAR_VERTICAL_COLLAPSED)) {
                html.classList.add(ClassNames.NAVBAR_VERTICAL_COLLAPSED_HOVER);
            }
        });
        navbarVerticalCollapse.addEventListener(Events.MOUSE_LEAVE, function () {
            if (utils.hasClass(html, ClassNames.NAVBAR_VERTICAL_COLLAPSED_HOVER)) {
                html.classList.remove(ClassNames.NAVBAR_VERTICAL_COLLAPSED_HOVER);
            }
        });
    }
}
 
</script>
