<template>
<main class="main" id="top" style="margin-top:-20px">
    <div class="container-fluid">
        <div class="row min-vh-100 bg-100">
            <div class="col-6 d-none d-lg-block position-relative">
                <div class="bg-holder" style="background-image:url(assets/img/avidi_img/custom_img/cinnamon_air.jpg);background-position: 50% 20%;">
                </div>
            </div>
            <div class="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
                <div class="row justify-content-center g-0">
                    <div class="col-lg-9 col-xl-8 col-xxl-6">
                        <div class="card">
                            <div class="card-header bg-circle-shape bg-shape text-center p-2"><a class="font-sans-serif fw-bolder fs-4 z-index-1 position-relative link-light light" href="#">AVIDI</a></div>
                            <div class="card-body p-4">
                                <div class="row flex-between-center">
                                    <div class="col-auto">
                                        <h3>Forget Password</h3>
                                    </div>
                                </div>
                                <form @submit.stop.prevent="RequestResetLink" autocomplete="off">
                                    <div class="mb-3">
                                        <b-form-group id="login-input-group-1" class="dark__text-white" label="Email Address" label-for="email">
                                            <b-form-input type="email" id="email" placeholder="Email Address" name="email" v-model="$v.form.email.$model" :state="validateState('email')" aria-describedby="input-email-feedback"></b-form-input>
                                            <b-form-invalid-feedback id="input-email-feedback">This is a required field.</b-form-invalid-feedback>
                                        </b-form-group>
                                    </div>
                                    <div class="row flex-between-center">
                                        <div class="col-auto">
                                        </div>
                                        <div class="col-auto"><a class="fs--1" href="login">Remember Password?</a></div>
                                    </div>
                                    <div class="mb-3">
                                        <button class="btn btn-primary d-block w-100 mt-3" :disabled="btnDisable" type="submit" name="submit">Send Reset Link</button>
                                    </div>
                                </form>
                                <span style="color:green">{{success}}</span>
                                <span style="color:red">{{error}}</span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
</template>

<script>
import {
    validationMixin
} from "vuelidate";
import {
    required,
    minLength
} from "vuelidate/lib/validators";
import API from "../apiConfig";

export default {
    mixins: [validationMixin],
    data() {
        return {
            api: API.API_URL,
            baseUrl: API.BASE_URL,
            form: {
                email: null,
            },
            btnDisable: false,
            success: '',
            error: ''
        }
    },
    validations: {
        form: {
            email: {
                required
            }
        }
    },
    methods: {

        validateState(name) {
            const {
                $dirty,
                $error
            } = this.$v.form[name];
            return $dirty ? !$error : null;
        },

        RequestResetLink: function () {

            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return false;
            } else {
                const vm = this;
                vm.btnDisable = true;
                vm.error = "";
                vm.success = "";
                axios({
                    method: "POST",
                    url: vm.baseUrl+'/'+vm.api + "/forget-password",
                    data: vm.form,
                }).then(function (response) {
                    if (response.data.status === true) {
                        console.log('in');
                        // localStorage.setItem('AppToken', response.data.token.plainTextToken)
                        // window.location.replace('/');
                        vm.success = response.data.message
                        //       vm.$toast.open({
                        //     message: response.data.message,
                        //     type: 'success',
                        // }); 
                    } else {
                        vm.error = response.data.message;
                        vm.btnDisable = false;
                    }
                });
            }
        },

    }
}
</script>
