<template>
<main class="main" id="top" style="margin-top:-20px">
    <div class="container-fluid">

        <div class="row min-vh-100 bg-100">
            <div class="col-6 d-none d-lg-block position-relative">
                <div class="bg-holder" style="background-image:url(assets/img/avidi_img/custom_img/cinnamon_air.jpg);background-position: 50% 20%;">
                </div>
            </div>
            <div class="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
                <div class="row justify-content-center g-0">
                    <div class="col-lg-9 col-xl-8 col-xxl-6">
                        <div class="card">
                            <div class="card-header bg-circle-shape bg-shape text-center p-2"><a class="font-sans-serif fw-bolder fs-4 z-index-1 position-relative link-light light" href="#">AVIDI</a></div>
                            <div class="card-body p-4">
                                <div class="row flex-between-center">
                                    <div class="col-auto">
                                        <h3>Reset Password</h3>
                                    </div>
                                </div>
                                <span style="color:red">{{errors}}</span>
                                <form @submit.stop.prevent="ResetPassword" autocomplete="off">
                                    
                                    <div class="mb-3">
                                        <b-form-group id="login-input-group-1" class="dark__text-white" label="Email Address" label-for="email">
                                            <b-input-group>
                                                <b-form-input type="email" id="email" placeholder="Email Address" name="email" v-model="$v.form.email.$model" :state="validateState('email')" aria-describedby="input-email-feedback"></b-form-input>
                                                <b-input-group-prepend is-text>
                                                    <b-icon icon="envelope"></b-icon>
                                                </b-input-group-prepend>
                                                <b-form-invalid-feedback id="input-email-feedback">This is a required field.</b-form-invalid-feedback>
                                            </b-input-group>
                                        </b-form-group>

                                    </div>
                                    <div class="mb-3">
                                        <b-form-group id="login-input-group-2" class="dark__text-white" label="New Password" label-for="password">
                                            <b-input-group>
                                                <b-form-input :type="pwType" id="password" placeholder="Password" name="password" v-model="$v.form.password.$model" :state="validateState('password')" aria-describedby="input-password-feedback"></b-form-input>
                                                <b-input-group-prepend is-text>
                                                    <b-icon v-show="showPW" @click="hidePassword" icon="eye-slash"></b-icon>
                                                    <b-icon v-show="!showPW" icon="eye" @click="showPassword"></b-icon>
                                                </b-input-group-prepend>
                                                <b-form-invalid-feedback id="input-password-feedback">This is a required field.</b-form-invalid-feedback>
                                            </b-input-group>
                                        </b-form-group>
                                    </div>
                                    <div class="mb-3">
                                        <b-form-group id="login-input-group-2" class="dark__text-white" label="Confirm Password" label-for="password_confirmation">
                                            <b-input-group>
                                                <b-form-input :type="pcType" id="password_confirmation" placeholder="Confirm Password" name="password_confirmation" v-model="$v.form.password_confirmation.$model" :state="validateState('password_confirmation')" aria-describedby="input-password_confirmation-feedback"></b-form-input>
                                                <b-input-group-prepend is-text>
                                                    <b-icon v-show="showPC" @click="hidePassword_confirmation" icon="eye-slash"></b-icon>
                                                    <b-icon v-show="!showPC" icon="eye" @click="showPassword_confirmation"></b-icon>
                                                </b-input-group-prepend>
                                                <b-form-invalid-feedback id="input-password_confirmation-feedback">This is a required field.</b-form-invalid-feedback>
                                            </b-input-group>
                                        </b-form-group>
                                    </div>
                                    <div class="mb-3">
                                        <button class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">Reset</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
</template>

<script>
import {
    validationMixin
} from "vuelidate";
import {
    required,
    minLength
} from "vuelidate/lib/validators";
import API from "../apiConfig";

export default {
    name: "resetPassword",
    mixins: [validationMixin],
    data() {
        return {
            api: API.API_URL,
            baseUrl: API.BASE_URL,
            form: {
                email: null,
                password: null,
                password_confirmation: null,
            },
            errors: null,
            showPW: false,
            pwType: 'password',

            showPC: false,
            pcType: 'password'
        }
    },
    validations: {
        form: {
            email: {
                required
            },
            password: {
                required
            },
            password_confirmation: {
                required
            }
        }
    },
    methods: {

        showPassword() {
            const vm = this;
            vm.showPW = !vm.showPW,
                vm.pwType = 'text'
        },
        showPassword_confirmation() {
            const vm = this;
            vm.showPC = !vm.showPC,
                vm.pcType = 'text'
        },
        hidePassword_confirmation() {
            const vm = this;
            vm.showPC = !vm.showPC,
                vm.pcType = 'password'
        },

        hidePassword() {
            const vm = this;
            vm.showPW = !vm.showPW,
                vm.pwType = 'password'
        },

        ResetPassword: function () {

            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return false;
            } else {
                const vm = this;
                if(vm.form.password === vm.form.password_confirmation){

                } else {
                    vm.errors = 'Password does not match';
                }
            }
        },
        validateState(name) {
            const {
                $dirty,
                $error
            } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
    },
}
</script>
