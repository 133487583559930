<template>
<!-- FOOTER AREA -->
<footer class="footer">
    <div class="row g-0 justify-content-between fs--1 mt-4 mb-3">
        <div class="col-12 col-sm-auto text-center">
            <p class="mb-0 text-600">AviDi <span class="d-none d-sm-inline-block">| </span><br class="d-sm-none" /> {{new Date().getFullYear()}} <sup>&copy;</sup> <a href="https://digitable.io">AviDi</a></p>
        </div>
        <div class="col-12 col-sm-auto text-center">
            <p class="mb-0 text-600">v3.4.0</p>
        </div>
    </div>
</footer>
</template>
<script>
export default {
   
}
</script>