require("./bootstrap");
require("../css/app.css");
import API from "./components/apiConfig.json";
import Vue from "vue";
import Vuex from 'vuex';
import store from './store';
import router from "./routes/routes";
import ECharts from "vue-echarts";
import rightBar from "./components/Layouts/rightbarComponent.vue";
import leftBar from "./components/Layouts/leftbarComponent.vue"; 
import footer from "./components/Layouts/footerComponent.vue";
import vueTable2 from "./components/vueTable2.vue";

// import vueTable3 from "./components/vueTable3.vue";
import VueEditableGrid from "vue-editable-grid";
import "vue-editable-grid/dist/VueEditableGrid.css";
import Vuetify from "vuetify";
import LaravelPermissionToVueJS from "laravel-permission-to-vuejs";
import myUpload from "vue-image-crop-upload/upload-2.vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import { BToast } from "bootstrap-vue";

import "datatables.net";
import "datatables.net-fixedcolumns";
import moment from "moment";
import 'moment-timezone';
import VTooltip from "v-tooltip";
import vSelect from "vue-select";
import ToggleButton from "vue-js-toggle-button";
import Swatches from "vue-swatches";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import Multiselect from "vue-multiselect";
import PHPUnserialize from 'php-unserialize';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';  
import 'flatpickr/dist/plugins/monthSelect/style.css'; 
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import infiniteScroll from 'vue-infinite-scroll'
import VueAlertify from 'vue-alertify';

Vue.use(VueAlertify,{
    glossary: {
        // dialogs default title
        title: 'AVIDI ALERT !',
        // ok button text
        ok: 'YES',
        // cancel button text
        cancel: 'NO',
      }
});

Vue.use(Vuex);
Vue.use(infiniteScroll);
Vue.use(VueSweetalert2);
Vue.use(VueToast,{
    // One of the options
    position: 'top-right',
    duration:3000,
    dismissible:true
});
Vue.use(moment);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuetify);
Vue.use(LaravelPermissionToVueJS);
Vue.use(VTooltip);
Vue.use(ToggleButton);
Vue.use(Swatches);
Vue.use(NProgress);
Vue.use(PHPUnserialize);  
Vue.component("flatPickr", flatPickr);
Vue.component("Multiselect", Multiselect);
Vue.component("vue-timepicker", VueTimepicker);
Vue.component("v-select", vSelect);
Vue.component("b-toast", BToast);

Vue.component("myUpload", myUpload);
Vue.component(
    "login-component",
    require("./components/Auth/LoginComponent.vue").default
);
Vue.component(
    "checkin-component",
    require("./components/Passengers/checkInComponent.vue").default
);
// Vue.component(
//     "expiredpassword-component",
//     require("./components/Auth/ExpiredPasswordUpdatingComponent.vue").default
// );
Vue.component(
    "forgetpassword-component",
    require("./components/Auth/ForgetPasswordComponent.vue").default
);
Vue.component(
    "resetpassword-component",
    require("./components/Auth/resetPasswordComponent.vue").default
);

Vue.component(
    "nopermission-component",
    require("./components/Layouts/noPermissionComponent.vue").default
);

Vue.component("rightBar", rightBar);
Vue.component("leftBar", leftBar);
Vue.component("myFooter", footer);

Vue.component("vue-editable-grid", VueEditableGrid);

Vue.prototype.can = function (value) {
    return window.Laravel.jsPermissions.permissions.includes(value);
};
Vue.prototype.is = function (value) {
    return window.Laravel.jsPermissions.roles.includes(value);
};

window.router = router;
window.Fire = new Vue();

function checkIsRole(authorize) {
    let response = false;
    if (window.Laravel == undefined) {
        response = true;
        return response;
    }
    const roles = window.Laravel.jsPermissions["roles"];
    if(authorize.permissions != undefined ){
        authorize.permissions.forEach((auth, index) => {
            if (roles.includes(auth)) {
                response = true;
            }
        }); 
    }
    return response;
}

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start();
    }
    next();
});

router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    NProgress.done();
});

axios.interceptors.response.use((response) => {
    // console.log(response.status);
        if (response.status === 401) {
            location.reload();
            return;
        }
        return response;

});

router.beforeEach((to, from, next) => {
    const authorize = to.meta;
    const check = checkIsRole(authorize); 
    
    if (authorize && check && authorize.permissions != null) {
        next();
    } else {
        window.location.href= API.BASE_URL+'/no-permission';
       
    }
});

const app = new Vue({
    store,
    el: "#app",
    router,
    propsData: {
        roles: "auth_user",
    },
    vuetify: new Vuetify(),
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
}).$mount("#app");