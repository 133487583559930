<template>
<div>
    <main class="main" id="top" style="margin-top:-20px">
        <div class="container-fluid">
            <div class="row min-vh-100 bg-100">
                <div class="col-6 d-none d-lg-block position-relative">
                    <div class="bg-holder" style="background-image:url(assets/img/avidi_img/custom_img/cinnamon_air.jpg);background-position: 50% 20%;">
                    </div>
                </div>
                <div class="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
                    <div class="row justify-content-center g-0">
                        <div class="col-lg-9 col-xl-8 col-xxl-6">
                            <div class="card">
                                <div class="card-header bg-circle-shape bg-shape text-center p-2"><a class="font-sans-serif fw-bolder fs-4 z-index-1 position-relative link-light light" href="#">AVIDI</a></div>
                                <div class="card-body p-4" v-if="!checkinForm">
                                    <div class="row flex-between-center">
                                        <div class="col-auto">
                                            <h3>Check-In</h3>
                                        </div>
                                    </div>
                                    <form @submit.stop.prevent="checkPassportNo" id="passportForm">
                                        <div class="mb-3">
                                            <b-form-group id="login-input-group-1" class="dark__text-white" label="Passport Number" label-for="passportNo">
                                                <b-form-input type="text" id="passportNo" placeholder="Passport/NIC Number" name="email" v-model="$v.form.passportNo.$model" :state="validateState('passportNo')" aria-describedby="input-passportNo-feedback"></b-form-input>
                                                <b-form-invalid-feedback id="input-passportNo-feedback">This is a required field.</b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>

                                        <div class="mb-3">
                                            <button class="btn btn-primary d-block w-100 mt-3" :disabled="btnDisable" type="submit" name="submit">Find</button>
                                        </div>
                                    </form>
                                </div>
                                <div class="card-body p-4" v-if="checkinForm">
                                    <form @submit.stop.prevent="updatePassenger" id="updateForm">
                                        <div class="mb-3">
                                            <div class="row">
                                                <div class="col-4">
                                                    <b-form-group id="login-input-group-1" class="dark__text-white" label="Title" label-for="nameTitle">
                                                        <b-form-input type="text" id="nameTitle" placeholder="Title" name="nameTitle" v-model="$v.passengerForm.nameTitle.$model" :state="validateState('nameTitle')" aria-describedby="input-nameTitle-feedback"></b-form-input>
                                                        <b-form-invalid-feedback id="input-nameTitle-feedback">This is a required field.</b-form-invalid-feedback>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-8">
                                                    <b-form-group id="login-input-group-1" class="dark__text-white" label="Full Name" label-for="fullName">
                                                        <b-form-input type="text" id="fullName" placeholder="Full Name" name="fullName" v-model="$v.passengerForm.fullName.$model" :state="validateState('fullName')" aria-describedby="input-fullName-feedback"></b-form-input>
                                                        <b-form-invalid-feedback id="input-fullName-feedback">This is a required field.</b-form-invalid-feedback>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <b-form-group id="login-input-group-1" class="dark__text-white" label="Billing Name" label-for="billingName">
                                                <b-form-input type="text" id="billingName" placeholder="Billing Name" name="email" v-model="$v.passengerForm.billingName.$model" :state="validateState('billingName')" aria-describedby="input-billingName-feedback"></b-form-input>
                                                <b-form-invalid-feedback id="input-billingName-feedback">This is a required field.</b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="mb-3">
                                            <b-form-group id="login-input-group-1" class="dark__text-white" label="Email Address" label-for="email">
                                                <b-form-input type="email" id="email" placeholder="Email Address" name="email" v-model="$v.passengerForm.email.$model" :state="validateState('email')" aria-describedby="input-email-feedback"></b-form-input>
                                                <b-form-invalid-feedback id="input-email-feedback">This is a required field.</b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="mb-3">
                                            <b-form-group id="login-input-group-1" class="dark__text-white" label="Contact Number" label-for="contact">
                                                <b-form-input type="text" id="contact" placeholder="Contact Number" name="email" v-model="$v.passengerForm.contact.$model" :state="validateState('contact')" aria-describedby="input-contact-feedback"></b-form-input>
                                                <b-form-invalid-feedback id="input-contact-feedback">This is a required field.</b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>

                                        <small class="dark__text-white"> <b>Flight List</b> </small>
                                        <div class="mb-3">
                                            <table class="table fs--1 mb-0 table-bordered dark__text-white">
                                                <thead class="bg-200 text-900">
                                                    <tr>
                                                        <th class="sort pe-1 align-middle white-space-nowrap"></th>
                                                        <th class="sort pe-1 align-middle white-space-nowrap text-center"><small>Flight Date</small></th>
                                                        <th class="sort pe-1 align-middle white-space-nowrap text-center"><small>Ticket No.</small></th>
                                                        <th class="sort pe-1 align-middle white-space-nowrap text-center"><small>Segment</small></th>
                                                    </tr>
                                                </thead>
                                                <tbody class="list dark__text-white">
                                                    <tr v-for="(flight, index) in flights" :key="index">
                                                        <td>
                                                            <div class="form-check form-switch mt-3">
                                                                <input id="chk" class="form-check-input" type="checkbox" :value="flight.id" :checked="flight.isCheck" @click="selectFlight(flight.id)" /> 
                                                            </div>
                                                        </td>
                                                        <td><small>{{ flight.flight_date }}</small></td>
                                                        <td><small>{{ flight.tkne }}</small></td>
                                                        <td><small>{{ flight.segment }}</small></td>
                                                    </tr>
                                                </tbody>

                                            </table>

                                        </div>

                                        <div class="mb-3">
                                            <button class="btn btn-primary d-block w-100 mt-3" :disabled="btnDisable" type="submit" name="submit">Check-In</button>
                                        </div>
                                    </form>

                                </div>

                                <span style="color:green">{{success}}</span>
                                <span style="color:red">{{error}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>

</div>
</template>

<script>
import {
    validationMixin
} from "vuelidate";
import {
    required,
    minLength
} from "vuelidate/lib/validators";
import countryList from "./countryList";
import API from "../apiConfig";
import axios from 'axios';

export default {
    name: "check-in",
    mixins: [validationMixin],

    data() {
        return {
            api: API.API_URL, // Main Api URL
            baseUrl: API.BASE_URL,
            AuthUser: this.auth_user, // AUTH USER
            // Axios Header
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
                Accept: "application/json",
            },
            checkinForm: false,
            btnDisable: false,
            error: '',
            success: '',
            form: {
                passportNo: null//'652500898'
            },
            flights: [],
            passengerForm: {
                nameTitle: null,
                fullName: null,
                passengerId: null,
                billingName: null,
                email: null,
                contact: null,
                checkin: []
            }
        }
    },
    validations: {
        form: {
            passportNo: {
                required
            },
        },
        passengerForm: {
            nameTitle: {
                required
            },
            fullName: {
                required
            },
            billingName: {
                required
            },
            email: {
                required
            },
            contact: {
                required
            }
        }
    },

    async mounted() {
        // this.checkPassportNo();
    },

    methods: {
        validateState(name) {
            const {
                $dirty,
                $error
            } = this.$v.form[name] || this.$v.passengerForm[name];
            return $dirty ? !$error : null;
        },

        checkPassportNo() {
            const vm = this;
            vm.$v.form.$touch();
            if (vm.$v.form.$anyError) {
                return false;
            }
            axios({
                method: "POST",
                url: vm.baseUrl + '/' + vm.api + "/passenger-checkin",
                data: vm.form,
            }).then(function (response) {
                if (response.data.status === true) {
                    $("#passportForm")[0].reset();
                    let records = response.data.data;
                    vm.passengerForm = {
                        nameTitle: records.nameTitle,
                        fullName: records.fullName,
                        passengerId: records.passengerId,
                        billingName: records.billingName,
                        email: records.email,
                        contact: records.contact,
                        checkin: []
                    };
                    vm.flights = records.flights;
                    vm.checkinForm = true;

                } else {
                    vm.$toast.open({
                        message: response.data.message,
                        type: 'error',
                    });
                }
            }).catch((e) => {
                console.log('Unauthenticated');
            });
        },

        updatePassenger() {
            const vm = this;
            vm.$v.passengerForm.$touch();
            if(vm.$v.passengerForm.$anyError){
                return false;
            }
            axios({
                method: "POST",
                url: vm.baseUrl + '/' + vm.api + "/passenger-checkin-update",
                data: vm.passengerForm,
            }).then(function (response) {
                if (response.data.status === true) {
                    $("#updateForm")[0].reset();
                    vm.$toast.open({
                        message: response.data.message,
                        type: 'success',
                    });
                    vm.checkinForm = false;
                } else {
                    vm.$toast.open({
                        message: response.data.message,
                        type: 'error',
                    });
                }
            }).catch((e) => {
                console.log('Unauthenticated');
            });
        },

        selectFlight(id){
            const vm  = this;
            vm.passengerForm.checkin.includes(id) ? vm.passengerForm.checkin = vm.passengerForm.checkin.filter(s => s !== id) : vm.passengerForm.checkin.push(id);
        }

    }
}
</script>
