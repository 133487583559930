const pageNotFound = () => import('../components/Layouts/notFoundComponent.vue');
const index = () =>import ( '../components/IndexComponent.vue');   
const profile = () => import('../components/Profile/newprofileComponent.vue');
const roster = () => import('../components/Roster/rosterComponent.vue'); 
const flightPlanner = () => import('../components/Flight/flightplannerComponent.vue');
const flightPlanCreate = () => import('../components/Flight/components/createPlannerComponent.vue');
const system = () => import('../components/System/systemComponent.vue');
const timeSheet = () => import('../components/Timesheet/timeSheetComponent.vue'); 
const crm = () => import('../components/Passengers/crmComponent.vue'); 
const leave = () => import('../components/Leave/leaveComponent.vue'); 
const reports = () => import('../components/Reports/reportComponent.vue'); 
const flightRoutes = () => import('../components/Routes/flightroutesComponent.vue');
const speedGo = () => import('../components/SpeedGo/speedgoComponent.vue'); 

const Foo = { template: '<div>foo</div>' }
const Bar = { template: '<div>bar</div>' }
const Baz = { template: '<div>baz</div>' }
const Default = { template: '<div>default</div>' }
const Nested = { template: '<router-view/>' }
const NestedFoo = { template: '<div>nested foo</div>' }

export default [ 
    { 
         path: "*", 
         component: pageNotFound,
         name: 'pageNotFound',
         meta:{permissions:['DISPATCHERS', 'ADMINISTRATOR', 'CAPT', 'FO', 'CTM', 'HODM', 'USER ADMIN']},
    },

    {
        path: '/',
        component: index,
        name: 'index', 
        meta: {permissions:['DISPATCHERS', 'ADMINISTRATOR', 'CAPT', 'FO', 'CTM', 'HODM', 'USER ADMIN']}
    }, 
    {
        path: '/roster',
        component: roster,
        name: 'roster',  
        meta:{permissions:['ADMINISTRATOR', 'DFO', 'USER ADMIN']}
    },
 
    {
        path: '/my-profile/:id?',
        component: profile,
        name: 'profile',  
        meta:{permissions:['DISPATCHERS', 'ADMINISTRATOR', 'CAPT', 'FO', 'CTM', 'HODM', 'USER ADMIN']}
    },

    {
        path: '/time-sheets/:id?',
        component: timeSheet,
        name: 'timeSheet',  
        meta:{permissions:['DISPATCHERS', 'ADMINISTRATOR', 'CAPT', 'FO', 'USER ADMIN']}
    }, 

    {
        path: '/flight-planner',
        component: flightPlanner,
        name: 'flightPlanner',
        meta:{permissions:['DISPATCHERS', 'ADMINISTRATOR', 'CAPT', 'FO', 'USER ADMIN']},
        children: [ 
            { path: 'create-planner', component: Foo, meta:{permissions:['DISPATCHERS', 'ADMINISTRATOR', 'USER ADMIN']} },
            // relative alias (alias to /home/bar-alias)
            // { path: 'manage-planner', component: flightPlanner, alias:  ['/baz', 'baz-alias'],  meta:{permissions:['DISPATCHER', 'ADMINISTRATOR']}}
        ]
    },

    {
        path: '/system',
        component: system,
        name: 'system',
        meta:{permissions:['ADMINISTRATOR', 'USER ADMIN', 'DISPATCHERS']}
    },

    {
        path: '/crm',
        component: crm,
        name: 'crm',
        meta:{permissions:['DISPATCHERS', 'ADMINISTRATOR', 'CTM', 'HODM', 'USER ADMIN']}
    },

    {
        path: '/leave',
        component: leave,
        name: 'leave',
        meta:{
            permissions:['DISPATCHERS', 'ADMINISTRATOR', 'USER ADMIN']
        },
        
    },

    {
        path: '/reports',
        component: reports,
        name: 'reports',
        meta:{
            permissions:['DFO', 'DISPATCHERS', 'ADMINISTRATOR', 'USER ADMIN']
        },
    },

    {
        path: '/flight-routes',
        component: flightRoutes,
        name: 'flightRoutes',
        meta:{
            permissions:['DFO', 'DISPATCHERS', 'ADMINISTRATOR', 'USER ADMIN']
        },
    },

    {
        path: '/speed-go',
        component: speedGo,
        name: 'speedGo',
        meta:{
            permissions:['DISPATCHERS', 'ADMINISTRATOR', 'USER ADMIN']
        },
    }


    
 
];




 