// store.js
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    recencyList: [],
  },
  mutations: {
    SET_RECENCIES(state, recencyList) {
      state.recencyList = recencyList;
    },
  },
  actions: {
    // fetchItems({ commit }) {
    //   console.log('AAAA');
    //   return axios.get('YOUR_API_ENDPOINT')
    //     .then(response => {
    //       commit('SET_ITEMS', response.data);
    //     })
    //     .catch(error => {
    //       console.error('Error fetching items:', error);
    //     });
    // },
    fetchRecencies({ commit }, apiUrl) {
      return axios.get(apiUrl)
        .then((response) => {
          if(response.data.status){
            commit('SET_RECENCIES', response.data.data);
          }
        }).catch(error => {
          console.error('Error fetching recencies:', error);
        });
    }
  },
  getters: {
    recencyList: state => state.recencyList,
  },
});
