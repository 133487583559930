<template>
<main class="main" id="top">
    <div class="container" data-layout="container">

        <div class="row flex-center min-vh-100 py-6 text-center">
            <div class="col-sm-10 col-md-8 col-lg-6 col-xxl-5">
                <a class="d-flex flex-center mb-4" href="#">
                    <img class="me-2" src="" alt="" width="58" />
                    <span class="font-sans-serif fw-bolder fs-5 d-inline-block">AVIDI</span>
                </a>
                <div class="card">
                    <div class="card-body p-4 p-sm-5">
                        <div class="fw-black lh-1 text-300 fs-error">503</div>
                        <h4 class="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">Oops !</h4>
                        <p class="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">You do not have permission to access this feature.</p>
                        <hr />
                        <center><button class="btn  btn-primary d-block w-50 mt-3" @click="signOut" type="button" name="submit">SignOut</button></center>

                        <!-- <router-link class="btn btn-primary btn-sm mt-3" to="/">
                            <span class="fas fa-home me-2"></span>
                            
                        </router-link> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
</template>

<script>
// var isFluid = JSON.parse(localStorage.getItem('isFluid'));
// if (isFluid) {
//     var container = document.querySelector('[data-layout]');
//     container.classList.remove('container');
//     container.classList.add('container-fluid');
// }
export default {
    name: "noPermission",

    methods: {
        signOut() {
            const vm = this;
            axios.post("api/signout", {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('AppToken'),
                }
            }).then(response => {
                window.location.href='/';
                window.localStorage.clear();
                
            });
             
        }
    }
}
</script>
