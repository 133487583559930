import Vue from 'vue'
import VueRouter from 'vue-router'
import routeList from './routeList'; 
Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',  
    // base: __dirname, 
    base: 'avidi', 
    scrollBehavior: (to, from, savedPosition) => ({ y: 0 }), 
    assetsPublicPath: '',
    relative: true,
    routes: [...routeList],
  
});
