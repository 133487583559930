<template>
<main class="main" id="top" style="margin-top:-20px">
    <div class="container-fluid">

        <div class="row min-vh-100 bg-100">
            <div class="col-6 d-none d-lg-block position-relative">
                <div class="bg-holder" style="background-image:url(assets/img/avidi_img/custom_img/cinnamon_air.jpg);background-position: 50% 20%;">
                </div>
            </div>
            <div class="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
                <div class="row justify-content-center g-0">
                    <div class="col-lg-9 col-xl-8 col-xxl-6">
                        <div class="card">
                            <div class="card-header bg-circle-shape bg-shape text-center p-2"><a class="font-sans-serif fw-bolder fs-4 z-index-1 position-relative link-light light" href="#">AVIDI</a></div>
                            <div class="card-body p-4">
                                <div class="row flex-between-center">
                                    <div class="col-auto">
                                        <h3>Login</h3>
                                    </div>
                                </div>
                                <span style="color:red">{{errors}}</span>
                                <form @submit.stop.prevent="UserLogin" autocomplete="off">
                                    <div class="mb-3">
                                        <b-form-group id="login-input-group-1" class="dark__text-white" label="Email Address" label-for="email">
                                            <b-input-group>
                                                <b-form-input type="email" id="email" placeholder="Email Address" name="email" v-model="$v.form.email.$model" :state="validateState('email')" aria-describedby="input-email-feedback"></b-form-input>
                                                <b-input-group-prepend is-text style="display:flex;">
                                                    <b-icon icon="envelope"></b-icon>
                                                </b-input-group-prepend>
                                                <b-form-invalid-feedback id="input-email-feedback">This is a required field.</b-form-invalid-feedback>
                                            </b-input-group>
                                        </b-form-group>

                                    </div>
                                    <div class="mb-3">
                                        <b-form-group id="login-input-group-2" class="dark__text-white" label="Password" label-for="password">
                                            <b-input-group>
                                                <b-form-input :type="pwType" id="password" placeholder="Password" name="password" v-model="$v.form.password.$model" :state="validateState('password')" aria-describedby="input-password-feedback"></b-form-input>
                                                 
                                                <b-input-group-prepend is-text @click="hidePassword" style="display:flex;">
                                                    <b-icon v-show="showPW"  icon="eye-slash"></b-icon>
                                                    <b-icon v-show="!showPW"  icon="eye"></b-icon>
                                                </b-input-group-prepend>
                                                <b-form-invalid-feedback id="input-password-feedback">This is a required field.</b-form-invalid-feedback>
                                            </b-input-group>
                                        </b-form-group>

                                    </div>
                                    <div class="row flex-between-center">
                                        <div class="col-auto">
                                            <div class="form-check mb-0">
                                                <input class="form-check-input" type="checkbox" v-model="form.remember_me" name="remember_me" id="split-checkbox" />
                                                <label class="form-check-label mb-0" for="split-checkbox">Remember Me</label>
                                            </div>
                                        </div>
                                        <div class="col-auto"><a class="fs--1" href="forget-password">Forgot Password?</a></div> 
                                    </div>
                                    <div class="mb-3">
                                        <button class="btn btn-primary d-block w-100 mt-3" :disabled="disableBtn" type="submit" name="submit">Log In</button>
                                    </div>

                                    <div class="position-relative mt-4">
                                        <hr>
                                        <div class="divider-content-center">or log in with</div>
                                    </div>
                                    <div class="row g-2 mt-2">
                                        <div class="col-sm-12"> 
                                            <a class="btn btn-outline-facebook d-block w-100" target="_blank" href="saml2/azure/login">
                                                <img src="assets/img/avidi_img/microsoft_icon.png" style="width:5%" />
                                                &nbsp;
                                                Azure Account
                                            </a>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
</template>

<script>
import {
    validationMixin
} from "vuelidate";
import {
    required,
    minLength
} from "vuelidate/lib/validators";
// import API from "../../apiConfig";
import API from "../apiConfig";

export default {

    mixins: [validationMixin],
    data() {
        return { 
            api: API.API_URL,
            baseUrl: API.BASE_URL,
            form: {
                email: null,
                password: null,
                remember_me: null,
            },
            errors: null,
            showPW: true,
            pwType: 'password',
            disableBtn:false,
        }
    },
    validations: {
        form: {
            email: {
                required
            },
            password: {
                required
            },
        }
    },
    methods: {
 
        hidePassword() {
            const vm = this;
            vm.showPW = !vm.showPW;
            if(vm.showPW){
                vm.pwType = 'password'
            } else{
                vm.pwType = 'text'
            }
             
        },

        UserLogin() { 
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return false;
            } else {
                const vm = this; 
                vm.disableBtn = true;
                axios({
                    method: "POST",
                    url: vm.baseUrl+'/'+vm.api + "/custom-login",
                    data: vm.form,
                }).then(function (response) { 
                    // console.log('response-->', response);
                    if (response.data.status === true) { 
                        localStorage.setItem('AppToken', response.data.token.plainTextToken);
                        localStorage.setItem('TableView', response.data.tableView);
                        router.go("/"); 
                        vm.disableBtn = false;
                    } else {
                        vm.errors = response.data.message;
                        vm.disableBtn = false;
                    }
                });
            }
        },
        validateState(name) {
            const {
                $dirty,
                $error
            } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
    },
}
</script>
